var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from '@contexts/AuthContext';
import PrivateRoute from '@components/PrivateRoute';
import AuthHandler from '@components/AuthHandler';
import './styles/fonts.css';
import './styles/App.css';
// Updated imports
import Home from '@pages/Home';
import Login from '@pages/Login';
import Dashboard from '@pages/Dashboard';
import OpenPage from '@pages/OpenPage';
import SecurePage from '@pages/SecurePage';
import ForgotPassword from '@pages/ForgotPassword';
import SignUp from '@pages/SignUp';
import VerifyEmail from '@pages/VerifyEmail';
var App = function (_a) {
    var isDevServer = _a.isDevServer;
    return (_jsx(Router, { children: _jsx(AuthProvider, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(Home, {}) }), _jsx(Route, { path: "/login", element: _jsx(Login, {}) }), _jsx(Route, { path: "/forgot-password", element: _jsx(ForgotPassword, {}) }), _jsx(Route, { path: "/auth", element: _jsx(AuthHandler, {}) }), _jsx(Route, { path: "/signup", element: _jsx(SignUp, {}) }), _jsx(Route, { path: "/verify-email", element: _jsx(VerifyEmail, {}) }), _jsx(Route, { path: "/open", element: _jsx(OpenPage, {}) }), _jsxs(Route, __assign({ element: _jsx(PrivateRoute, {}) }, { children: [_jsx(Route, { path: "/dashboard", element: _jsx(Dashboard, {}) }), _jsx(Route, { path: "/secure", element: _jsx(SecurePage, {}) })] }))] }) }) }));
};
export default App;
