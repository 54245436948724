var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
// src/contexts/AuthContext.tsx
import { createContext, useState, useEffect, useContext } from 'react';
import { userPool } from '../utils/cognito';
import { refreshToken } from '../utils/tokenRefresh';
var AuthContext = createContext(undefined);
export var AuthProvider = function (_a) {
    var children = _a.children;
    var _b = useState(false), isAuthenticated = _b[0], setIsAuthenticated = _b[1];
    var _c = useState(null), token = _c[0], setToken = _c[1];
    var _d = useState(null), cognitoUserId = _d[0], setCognitoUserId = _d[1];
    var checkAuthStatus = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve) {
                    var cognitoUser = userPool.getCurrentUser();
                    if (cognitoUser) {
                        cognitoUser.getSession(function (err, session) {
                            if (err) {
                                setIsAuthenticated(false);
                                setToken(null);
                                setCognitoUserId(null);
                                resolve(false);
                            }
                            else if (session && session.isValid()) {
                                setIsAuthenticated(true);
                                setToken(session.getIdToken().getJwtToken());
                                // Get the Cognito User ID from the token payload
                                var payload = session.getIdToken().decodePayload();
                                setCognitoUserId(payload.sub || null);
                                resolve(true);
                            }
                            else {
                                setIsAuthenticated(false);
                                setToken(null);
                                setCognitoUserId(null);
                                resolve(false);
                            }
                        });
                    }
                    else {
                        setIsAuthenticated(false);
                        setToken(null);
                        setCognitoUserId(null);
                        resolve(false);
                    }
                })];
        });
    }); };
    var getToken = function () { return __awaiter(void 0, void 0, void 0, function () {
        var decodedToken, expirationTime, currentTime, fiveMinutes, newToken, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!!token) return [3 /*break*/, 2];
                    return [4 /*yield*/, checkAuthStatus()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    if (!token) return [3 /*break*/, 6];
                    decodedToken = JSON.parse(atob(token.split('.')[1]));
                    expirationTime = decodedToken.exp * 1000;
                    currentTime = Date.now();
                    fiveMinutes = 5 * 60 * 1000;
                    if (!(expirationTime - currentTime < fiveMinutes)) return [3 /*break*/, 6];
                    _a.label = 3;
                case 3:
                    _a.trys.push([3, 5, , 6]);
                    return [4 /*yield*/, refreshToken()];
                case 4:
                    newToken = _a.sent();
                    setToken(newToken);
                    return [2 /*return*/, newToken];
                case 5:
                    error_1 = _a.sent();
                    console.error('Failed to refresh token:', error_1);
                    setIsAuthenticated(false);
                    setToken(null);
                    setCognitoUserId(null);
                    return [2 /*return*/, null];
                case 6: return [2 /*return*/, token];
            }
        });
    }); };
    useEffect(function () {
        var rememberMe = localStorage.getItem('rememberMe');
        var sessionAuth = sessionStorage.getItem('isLoggedIn');
        if (rememberMe === 'true' || sessionAuth === 'true') {
            checkAuthStatus();
        }
        else {
            setIsAuthenticated(false);
            setToken(null);
            setCognitoUserId(null);
        }
    }, []);
    return (_jsx(AuthContext.Provider, __assign({ value: {
            isAuthenticated: isAuthenticated,
            setIsAuthenticated: setIsAuthenticated,
            checkAuthStatus: checkAuthStatus,
            getToken: getToken,
            cognitoUserId: cognitoUserId,
            setCognitoUserId: setCognitoUserId
        } }, { children: children })));
};
export var useAuth = function () {
    var context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
