// src/utils/apiConnector.js

import axios from 'axios';
import config from '../config/infrastructure.json';

class ApiConnector {
  constructor() {
    this.baseURL = this.getBaseURL();
    this.instance = axios.create({
      baseURL: this.baseURL,
    });

    // Add request interceptor
    this.instance.interceptors.request.use(
      (config) => {
        // You can add authentication headers here if needed
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  getBaseURL() {
    // Use the STAGE environment variable
    console.log('getBaseURL for stage: '+process.env.REACT_APP_STAGE)
    const stage = process.env.REACT_APP_STAGE || 'beta'; // Default to 'beta' if not set
    if (!config.apiEndpoints[stage]) {
      console.warn(`No API endpoint found for stage ` + stage +`. Defaulting to beta.`);
      return 'https://'+config.apiEndpoints.beta;
    }
    return 'https://'+config.apiEndpoints[stage];
  }

  async get(url, config = {}) {
    return this.instance.get(url, config);
  }

  async post(url, data, config = {}) {
    return this.instance.post(url, data, config);
  }

  async put(url, data, config = {}) {
    return this.instance.put(url, data, config);
  }

  async delete(url, config = {}) {
    return this.instance.delete(url, config);
  }
}

export default new ApiConnector();