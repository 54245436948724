import { userPool } from './cognito';
export var refreshToken = function () {
    return new Promise(function (resolve, reject) {
        var cognitoUser = userPool.getCurrentUser();
        if (!cognitoUser) {
            reject(new Error('No current user'));
            return;
        }
        cognitoUser.getSession(function (err, session) {
            if (err) {
                reject(err);
                return;
            }
            if (!session) {
                reject(new Error('No valid session'));
                return;
            }
            cognitoUser.refreshSession(session.getRefreshToken(), function (err, newSession) {
                if (err) {
                    reject(err);
                }
                else if (newSession) {
                    resolve(newSession.getIdToken().getJwtToken());
                }
                else {
                    reject(new Error('Failed to refresh token'));
                }
            });
        });
    });
};
