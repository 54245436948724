// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.landingPageTitle {
  font-family: 'Montserrat', sans-serif;
  font-size: calc(100px + 2vmin);
  color: white;
  letter-spacing: 20px;
  font-weight: 700;
}

.landingPageSubTitle {
  font-family: 'Montserrat', sans-serif;
  font-size: calc(10px + 2vmin);
  color: white;
  letter-spacing: 20px;
}

.devIndicator {
  font-size: 14px;
  color: #ff6b6b;
  margin-top: 10px;
}

.container {
  font-family: 'Montserrat', sans-serif;
  color: white;

}

.active {
  color: #ff5231 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /*animation: App-logo-spin infinite 20s linear;*/
  }
}

.App-header {
  background-color: #0d3654;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.bg-dark {
    background-color: #0d3654 !important;
}

.container {
     /*background-color: #0d3654;*/
 }

.App-link {
  color: #ff5231;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/App.css"],"names":[],"mappings":";;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,qCAAqC;EACrC,8BAA8B;EAC9B,YAAY;EACZ,oBAAoB;EACpB,gBAAgB;AAClB;;AACA;EACE,qCAAqC;EACrC,6BAA6B;EAC7B,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,qCAAqC;EACrC,YAAY;;AAEd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE;IACE,gDAAgD;EAClD;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;IACI,oCAAoC;AACxC;;AAEC;KACI,6BAA6B;CACjC;;AAED;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["\n\n.App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n.landingPageTitle {\n  font-family: 'Montserrat', sans-serif;\n  font-size: calc(100px + 2vmin);\n  color: white;\n  letter-spacing: 20px;\n  font-weight: 700;\n}\n.landingPageSubTitle {\n  font-family: 'Montserrat', sans-serif;\n  font-size: calc(10px + 2vmin);\n  color: white;\n  letter-spacing: 20px;\n}\n\n.devIndicator {\n  font-size: 14px;\n  color: #ff6b6b;\n  margin-top: 10px;\n}\n\n.container {\n  font-family: 'Montserrat', sans-serif;\n  color: white;\n\n}\n\n.active {\n  color: #ff5231 !important;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    /*animation: App-logo-spin infinite 20s linear;*/\n  }\n}\n\n.App-header {\n  background-color: #0d3654;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.bg-dark {\n    background-color: #0d3654 !important;\n}\n\n .container {\n     /*background-color: #0d3654;*/\n }\n\n.App-link {\n  color: #ff5231;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
