var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// src/pages/Login.tsx
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { userPool, getPoolConfig } from '@utils/cognito';
import { useAuth } from '@contexts/AuthContext';
import { validatePassword, getPasswordErrorMessage } from '@utils/passwordUtils';
var Login = function () {
    var _a = useState(''), email = _a[0], setEmail = _a[1];
    var _b = useState(''), password = _b[0], setPassword = _b[1];
    var _c = useState(''), newPassword = _c[0], setNewPassword = _c[1];
    var _d = useState(false), rememberMe = _d[0], setRememberMe = _d[1];
    var _e = useState(null), error = _e[0], setError = _e[1];
    var _f = useState(false), showNewPasswordField = _f[0], setShowNewPasswordField = _f[1];
    var _g = useState(null), newPasswordError = _g[0], setNewPasswordError = _g[1];
    var _h = useState(true), isLoading = _h[0], setIsLoading = _h[1];
    var _j = useState(false), enableSignups = _j[0], setEnableSignups = _j[1];
    var navigate = useNavigate();
    var _k = useAuth(), isAuthenticated = _k.isAuthenticated, checkAuthStatus = _k.checkAuthStatus, setCognitoUserId = _k.setCognitoUserId;
    var _l = useState(null), cognitoUser = _l[0], setCognitoUser = _l[1];
    useEffect(function () {
        var poolConfig = getPoolConfig();
        setEnableSignups(poolConfig.enableSignups);
    }, []);
    useEffect(function () {
        var checkAuth = function () { return __awaiter(void 0, void 0, void 0, function () {
            var authStatus;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, checkAuthStatus()];
                    case 1:
                        authStatus = _a.sent();
                        if (authStatus) {
                            navigate('/dashboard');
                        }
                        else {
                            setIsLoading(false);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        checkAuth();
    }, [checkAuthStatus, navigate]);
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var authenticationData, authenticationDetails, userData, cognitoUser;
        return __generator(this, function (_a) {
            e.preventDefault();
            setError(null);
            authenticationData = {
                Username: email,
                Password: password,
            };
            authenticationDetails = new AuthenticationDetails(authenticationData);
            userData = {
                Username: email,
                Pool: userPool
            };
            cognitoUser = new CognitoUser(userData);
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: function (result) { return __awaiter(void 0, void 0, void 0, function () {
                    var idToken, payload, cognitoUserId;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                console.log('Login successful!', result);
                                idToken = result.getIdToken();
                                payload = idToken.decodePayload();
                                cognitoUserId = payload.sub;
                                console.log('cognitoUserId', cognitoUserId);
                                if (cognitoUserId) {
                                    setCognitoUserId(cognitoUserId);
                                    // TO DO: call a function to map the user to their application profile
                                    // mapUserToProfile(cognitoUserId);
                                }
                                if (rememberMe) {
                                    localStorage.setItem('rememberMe', 'true');
                                }
                                else {
                                    localStorage.removeItem('rememberMe');
                                    sessionStorage.setItem('isLoggedIn', 'true');
                                }
                                return [4 /*yield*/, checkAuthStatus()];
                            case 1:
                                _a.sent();
                                navigate('/dashboard');
                                return [2 /*return*/];
                        }
                    });
                }); },
                onFailure: function (err) {
                    console.error('Login failed', err);
                    setError('Invalid email or password');
                },
                newPasswordRequired: function (userAttributes, requiredAttributes) {
                    setCognitoUser(cognitoUser);
                    setShowNewPasswordField(true);
                    setError('You need to set a new password');
                }
            });
            return [2 /*return*/];
        });
    }); };
    // const handleFederatedSignIn = async (provider: string) => {
    //     // Construct the authorization URL
    //     const authorizationUrl = new URL(`https://${config.cognito.userPoolDomain}/oauth2/authorize`);
    //     authorizationUrl.searchParams.append('identity_provider', provider);
    //     authorizationUrl.searchParams.append('redirect_uri', 'https://dev.routy.com:8080/auth'); // Update this for production
    //     authorizationUrl.searchParams.append('response_type', 'TOKEN');
    //     authorizationUrl.searchParams.append('client_id', config.cognito.userPoolClientId);
    //     authorizationUrl.searchParams.append('scope', 'email openid profile');
    //       // Generate a random string for the state parameter
    //     const state = Math.random().toString(36).substring(2, 15);
    //     authorizationUrl.searchParams.append('state', state);
    //     // Store the state in localStorage to verify it when we return
    //     localStorage.setItem('oauth_state', state);
    //     // Redirect to the provider's login page
    //     window.location.href = authorizationUrl.toString();
    // };
    var handleNewPasswordSubmit = function (e) {
        e.preventDefault();
        setError(null);
        setNewPasswordError(null);
        if (!validatePassword(newPassword)) {
            setNewPasswordError(getPasswordErrorMessage());
            return;
        }
        // const cognitoUser = userPool.getCurrentUser();
        console.log('cognitoUser', JSON.stringify(cognitoUser, null, 2));
        if (!cognitoUser) {
            setError('Something went wrong. Please try logging in again.');
            return;
        }
        // Provide dummy data for required attributes
        var userAttributes = {
            given_name: 'routy',
            family_name: 'User'
        };
        cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
            onSuccess: function (result) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            console.log('Password changed successfully');
                            if (rememberMe) {
                                localStorage.setItem('rememberMe', 'true');
                            }
                            else {
                                localStorage.removeItem('rememberMe');
                                sessionStorage.setItem('isLoggedIn', 'true');
                            }
                            return [4 /*yield*/, checkAuthStatus()];
                        case 1:
                            _a.sent();
                            navigate('/dashboard');
                            return [2 /*return*/];
                    }
                });
            }); },
            onFailure: function (err) {
                console.error('Failed to change password', err);
                setError('Failed to change password. Please try again.');
            }
        });
    };
    if (isLoading) {
        return _jsx("div", { children: "Loading..." });
    }
    return (_jsxs("div", __assign({ className: "login-container" }, { children: [_jsx("h2", { children: "Login" }), error && _jsx("div", __assign({ className: "error" }, { children: error })), !showNewPasswordField ? (_jsxs("form", __assign({ onSubmit: handleSubmit }, { children: [_jsxs("div", { children: [_jsx("label", __assign({ htmlFor: "email" }, { children: "Email:" })), _jsx("input", { type: "email", id: "email", value: email, onChange: function (e) { return setEmail(e.target.value); }, required: true })] }), _jsxs("div", { children: [_jsx("label", __assign({ htmlFor: "password" }, { children: "Password:" })), _jsx("input", { type: "password", id: "password", value: password, onChange: function (e) { return setPassword(e.target.value); }, required: true })] }), _jsx("div", { children: _jsxs("label", { children: [_jsx("input", { type: "checkbox", checked: rememberMe, onChange: function (e) { return setRememberMe(e.target.checked); } }), "Remember Me"] }) }), _jsx("button", __assign({ type: "submit" }, { children: "Log In" }))] }))) : (_jsxs("form", __assign({ onSubmit: handleNewPasswordSubmit }, { children: [_jsxs("div", { children: [_jsx("label", __assign({ htmlFor: "newPassword" }, { children: "New Password:" })), _jsx("input", { type: "password", id: "newPassword", value: newPassword, onChange: function (e) { return setNewPassword(e.target.value); }, required: true })] }), newPasswordError && _jsx("div", __assign({ className: "error" }, { children: newPasswordError })), _jsx("button", __assign({ type: "submit" }, { children: "Set New Password" }))] }))), _jsx("p", { children: _jsx(Link, __assign({ to: "/forgot-password" }, { children: "Forgot Password?" })) }), enableSignups && (_jsxs("p", { children: ["Don't have an account? ", _jsx(Link, __assign({ to: "/signup" }, { children: "Sign up" }))] }))] })));
};
export default Login;
