var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// src/pages/ForgotPassword.tsx
import { useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { userPool } from '../utils/cognito';
import { useNavigate } from 'react-router-dom';
import { validatePassword, getPasswordErrorMessage } from '@utils/passwordUtils';
var ForgotPassword = function () {
    var _a = useState(''), email = _a[0], setEmail = _a[1];
    var _b = useState(''), code = _b[0], setCode = _b[1];
    var _c = useState(''), newPassword = _c[0], setNewPassword = _c[1];
    var _d = useState(1), step = _d[0], setStep = _d[1];
    var _e = useState(null), error = _e[0], setError = _e[1];
    var _f = useState(null), passwordError = _f[0], setPasswordError = _f[1];
    var navigate = useNavigate();
    var handleSubmitEmail = function (e) {
        e.preventDefault();
        var userData = {
            Username: email,
            Pool: userPool
        };
        var cognitoUser = new CognitoUser(userData);
        cognitoUser.forgotPassword({
            onSuccess: function () {
                setStep(2);
            },
            onFailure: function (err) {
                setError(err.message || 'An error occurred');
            },
        });
    };
    var handleResetPassword = function (e) {
        e.preventDefault();
        setError(null);
        setPasswordError(null);
        if (!validatePassword(newPassword)) {
            setPasswordError(getPasswordErrorMessage());
            return;
        }
        var userData = {
            Username: email,
            Pool: userPool
        };
        var cognitoUser = new CognitoUser(userData);
        cognitoUser.confirmPassword(code, newPassword, {
            onSuccess: function () {
                alert('Password reset successful. Please login with your new password.');
                navigate('/login');
            },
            onFailure: function (err) {
                setError(err.message || 'An error occurred');
            },
        });
    };
    return (_jsxs("div", __assign({ className: "forgot-password-container" }, { children: [_jsx("h2", { children: "Reset Password" }), error && _jsx("div", __assign({ className: "error" }, { children: error })), step === 1 ? (_jsxs("form", __assign({ onSubmit: handleSubmitEmail }, { children: [_jsxs("div", { children: [_jsx("label", __assign({ htmlFor: "email" }, { children: "Email:" })), _jsx("input", { type: "email", id: "email", value: email, onChange: function (e) { return setEmail(e.target.value); }, required: true })] }), _jsx("button", __assign({ type: "submit" }, { children: "Send Reset Code" }))] }))) : (_jsxs("form", __assign({ onSubmit: handleResetPassword }, { children: [_jsxs("div", { children: [_jsx("label", __assign({ htmlFor: "code" }, { children: "Reset Code:" })), _jsx("input", { type: "text", id: "code", value: code, onChange: function (e) { return setCode(e.target.value); }, required: true })] }), _jsxs("div", { children: [_jsx("label", __assign({ htmlFor: "newPassword" }, { children: "New Password:" })), _jsx("input", { type: "password", id: "newPassword", value: newPassword, onChange: function (e) { return setNewPassword(e.target.value); }, required: true })] }), passwordError && _jsx("div", __assign({ className: "error" }, { children: passwordError })), _jsx("button", __assign({ type: "submit" }, { children: "Reset Password" }))] })))] })));
};
export default ForgotPassword;
